import { configureStore } from "@reduxjs/toolkit";
import clientPaginationReducer from "../pages/Clients/slices/PaginationSlice";
import loanPaginationReducer from "../pages/Loans/slices/PaginationSlice";
import transactionPaginationReducer from "../pages/Savings/Transactions/slices/PaginationSlice";
import transactionAccounts from "../pages/Savings/DisburseInterest/slices/PaginationSlice";

import savingReportReducer from "../pages/Reports/SavingReport/slices/PaginationSlice";
import cumulativeReportReducer from "../pages/Reports/CumulativeSavingReport/slices/PaginationSlice";
import savingOfficerReportReducer from "../pages/Reports/SavingsOfficer/slices/PaginationSlice";
import shareholderReportReducer from "../pages/Reports/Membership/slices/PaginationSlice";
import clientReportReducer from "../pages/Reports/ClientReport/slices/PaginationSlice";
import physicalAssetReportReducer from "../pages/Reports/PhysicalassetReport/slices/PaginationSlice";
import nonPhysicalAssetReportReducer from "../pages/Reports/NonPhysicalAssetReport/slices/PaginationSlice";
import physicalAssetSaleReportReducer from "../pages/Reports/PhysicalassetReport/slices/PaginationSliceSale";
import balanceSheetReportReducer from "../pages/Reports/BalanceSheet/slices/PaginationSlice";
import trialBalanceReportReducer from "../pages/Reports/TrialBalance/slices/PaginationSlice";
import incomeStatementReportReducer from "../pages/Reports/IncomeStatement/slices/PaginationSlice";
import cashflowReportReducer from "../pages/Reports/CashflowStatement/slices/PaginationSlice";
import expenseReportReducer from "../pages/Reports/ExpensesReport/slices/PaginationSlice";
import feesReportReducer from "../pages/Reports/FeesReport/slices/PaginationSlice";
import agingReportReducer from "../pages/Reports/AgeingReport/slices/PaginationSlice";
import loanArrearReportReducer from "../pages/Reports/LoanArrears/slices/PaginationSlice";
import loanStatusReportReducer from "../pages/Reports/LoanStatus/slices/PaginationSlice";
import loanDisburementReportReducer from "../pages/Reports/LoanDisbursement/slices/PaginationSlice";
import portfolioReportReducer from "../pages/Reports/PortfolioAtRisk/slices/PaginationSlice";
import cumulativeLoanRepaymentReducer from "../pages/Reports/LoanRepayment/slices/PaginationSlice";
import loanOfficerReportReducer from "../pages/Reports/LoanOfficerReport/slices/PaginationSlice";
import loanOfficerSummaryReducer from "../pages/Reports/LoanOfficerSummaryReport/slices/PaginationSlice";
import parAgingReportReducer from "../pages/Reports/ParAgingReport/slices/PaginationSlice";
import loanBookReportReducer from "../pages/Reports/LoanBook/slices/PaginationSlice";
import expectLoanPaymentReportReducer from "../pages/Reports/ExpectedLoanRepayment/slices/PaginationSlice";
import loanRepaymentTransactionReducer from "../pages/Reports/loanRepaymentTransactionReport/slices/PaginationSlice";
import suveryQuestionPaginationReducer from "../pages/Survey/slices/FormPaginationSlice";
import BranchPortfolioSummaryReportReducer from "../pages/Reports/BranchPortfolioSummary/slices/PaginationSlice";
import savingsProductPerformanceReducer from "../pages/Analytics/SavingsAndDeposit/SavingsProductPerformance/Table/slices/PaginationSlice";
import depositTrendsReducer from "../pages/Analytics/SavingsAndDeposit/DepositTrends/Table/slices/PaginationSlice";
import interestExpenseMonitoringReducer from "../pages/Analytics/SavingsAndDeposit/InterestExpenseMonitoring/Table/slices/PaginationSlice";
import expenseTrackingAndAnalysisReducer from "../pages/Analytics/RevenueAndExpenses/ExpenseTrackingAndAnalysis/Table/slices/PaginationSlice";
import profitMarginAnalysisReducer from "../pages/Analytics/RevenueAndExpenses/ProfitMarginAnalysis/Table/slices/PaginationSlice";
import revenuePerformanceTrackingReducer from "../pages/Analytics/RevenueAndExpenses/RevenuePerformanceTracking/Table/slices/PaginationSlice";
import bestAndWorstPerformingProductsReducer from "../pages/Analytics/ProductPerformance/BestAndWorstPerformingProducts/Table/slices/PaginationSlice";
import crossSellOpportunitiesReducer from "../pages/Analytics/ProductPerformance/CrossSellOpportunities/Table/slices/PaginationSlice";
import collectionOfficerGoalSettingsReducer from "../pages/Analytics/CollectionAndRecovery/CollectionOfficerGoalSettings/Table/slices/PaginationSlice";
import collectionOfficerPerformanceReducer from "../pages/Analytics/CollectionAndRecovery/CollectionOfficerPerformance/Table/slices/PaginationSlice";
import LoanPortfolioAnalysisReducer from "../pages/Analytics/LoanPerformance/LoanPortfolioAnalysis/Table/slices/PaginationSlice";
import DelinquencyRatesReducer from "../pages/Analytics/LoanPerformance/DelinquencyRates/Table/slices/PaginationSlice";
import LoanRepaymentTrendsReducer from "../pages/Analytics/LoanPerformance/LoanRepaymentTrends/Table/slices/PaginationSlice";
import TopPerformingLoansReducer from "../pages/Analytics/LoanPerformance/TopPerformingloans/Table/slices/PaginationSlice";
import CustomerSegmentationPaginationReducer from "../pages/Analytics/Customer/CustomerSegmentation/Table/slices/PaginationSlice";
import CustomerRetentionAndChurnPaginationReducer from "../pages/Analytics/Customer/CustomerRetentionAndChurn/Table/slices/PaginationSlice";
import ReferralTrackingPaginationReducer from "../pages/Analytics/Customer/ReferralTracking/Table/slices/PaginationSlice";
import BranchPerformancePaginationReducer from "../pages/Analytics/OperationEfficiency/BranchPerformance/Table/slices/PaginationSlice";
import StaffProductivityPaginationReducer from "../pages/Analytics/OperationEfficiency/StaffProductivity/Table/slices/PaginationSlice";
import loanOfficerPortfolioBalanceSummaryReducer from "../pages/Reports/LoanOfficerPortfolioBalanceSummaryReport/slices/PaginationSlice";

export const store = configureStore({
  reducer: {
    ClientPagination: clientPaginationReducer,
    LoanPagination: loanPaginationReducer,
    TransactionPagination: transactionPaginationReducer,
    ClientAccounts: transactionAccounts,

    // reports
    SavingReportPagination: savingReportReducer,
    CumulativeReportPagination: cumulativeReportReducer,
    SavingOfficerReportPagination: savingOfficerReportReducer,
    ShareholderReportPagination: shareholderReportReducer,
    ClientReportPagination: clientReportReducer,
    PhysicalAssetReportPagination: physicalAssetReportReducer,
    NonPhysicalAssetReportPagination: nonPhysicalAssetReportReducer,
    PhysicalAssetSaleReportPagination: physicalAssetSaleReportReducer,
    BalanceSheetReportPagination: balanceSheetReportReducer,
    TrialBalanceReportPagination: trialBalanceReportReducer,
    IncomeStatementReportPagination: incomeStatementReportReducer,
    CashflowReportPagination: cashflowReportReducer,
    ExpenseReportPagination: expenseReportReducer,
    FeesReportPagination: feesReportReducer,
    AgingReportPagination: agingReportReducer,
    LoanArrearReportPagination: loanArrearReportReducer,
    BranchPortfolioBalanceSummaryPagination:
      BranchPortfolioSummaryReportReducer,
    LoanStatusReportPagination: loanStatusReportReducer,
    LoanDisburementReportPagination: loanDisburementReportReducer,
    PortfolioReportPagination: portfolioReportReducer,
    CumulativeLoanRepaymentPagination: cumulativeLoanRepaymentReducer,
    LoanOfficerReportPagination: loanOfficerReportReducer,
    LoanOfficerSummaryPagination: loanOfficerSummaryReducer,
    ParAgingReportPagination: parAgingReportReducer,
    LoanBookReportPagination: loanBookReportReducer,
    ExpectLoanPaymentReportPagination: expectLoanPaymentReportReducer,
    LoanRepaymentTransactionPagination: loanRepaymentTransactionReducer,

    SurveyQuestionPagination: suveryQuestionPaginationReducer,

    // analytics
    SavingsProductPerformancePagination: savingsProductPerformanceReducer,
    DepositTrendsPagination: depositTrendsReducer,
    InterestExpenseMonitoringPagination: interestExpenseMonitoringReducer,
    ExpenseTrackingAndAnalysisPagination: expenseTrackingAndAnalysisReducer,
    ProfitMarginAnalysisPagination: profitMarginAnalysisReducer,
    RevenuePerformanceTrackingPagination: revenuePerformanceTrackingReducer,
    BestAndWorstPerformingProductsPagination:
      bestAndWorstPerformingProductsReducer,
    CrossSellOpportunitiesPagination: crossSellOpportunitiesReducer,
    CollectionOfficerGoalSettingsPagination:
      collectionOfficerGoalSettingsReducer,
    CollectionOfficerPerformancePagination: collectionOfficerPerformanceReducer,
    LoanPortfolioAnalysisPagination: LoanPortfolioAnalysisReducer,
    DelinquencyRatesPagination: DelinquencyRatesReducer,
    LoanRepaymentTrendsPagination: LoanRepaymentTrendsReducer,
    TopPerformingLoansPagination: TopPerformingLoansReducer,
    CustomerSegmentationPagination: CustomerSegmentationPaginationReducer,
    CustomerRetentionAndChurnPagination:
      CustomerRetentionAndChurnPaginationReducer,
    ReferralTrackingPagination: ReferralTrackingPaginationReducer,
    BranchPerfomancePagination: BranchPerformancePaginationReducer,
    StaffProductivityPagination: StaffProductivityPaginationReducer,
    LoanOfficerPortfolioBalanceSummaryPagination:
      loanOfficerPortfolioBalanceSummaryReducer,
  },
});
