import { Subject } from "rxjs";

const subject = new Subject();

const initialState: any = {};

let state = initialState;

const CurrenciesStore = {
  init: (data: any) => subject.next({ ...state, ...data }),
  subscribe: (setState: any) => subject.subscribe(setState),
  update: (payload: any) => {
    state = {
      currencies: [...payload],
      baseCurrency:
        [...payload].find((currency: any) => currency.default === true) || {},
    };
    subject.next(state);
  },
  initialState,
};

export default CurrenciesStore;
