import React, {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from "react";
import { useAuth } from "../hooks/useAuth";
import CurrenciesStore from "../store/currencies.store";
import { CurrencyRateService } from "../pages/Admin/BankSettings/CurrencySettings/models/services/CurrencyRates.service";

export interface ICurrenciesContext {
  currencies: any;
  baseCurrency: any;
  setCurrencies: (arg: any) => void;
  setBaseCurrency: (arg: any) => void;
}

export const CurrenciesContext = createContext<ICurrenciesContext | undefined>(
  undefined,
);

export const useCurrencyContext = () => {
  const context = useContext(CurrenciesContext);

  if (!context) {
    throw new Error(
      "useModalContext must be used within a Currency Context Provider",
    );
  }

  return context;
};

export const CurrenciesProvider: FC<any> = ({ children }) => {
  const auth: any = useAuth();
  const [currencies, setCurrencies] = useState<any>([]);
  const [baseCurrency, setBaseCurrency] = useState<any>({});
  const Provider = CurrenciesContext.Provider as any;

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const { results }: any = await CurrencyRateService.getCurrencies({
          bank: auth?.user.extra_details.bank,
          page_size: 100,
        });
        setCurrencies(results);
        setBaseCurrency(
          results.find((currency: any) => currency.default === true) || {},
        );
        CurrenciesStore.update(results);
      } catch (error) {
        CurrenciesStore.update([]);
      }
    };
    fetchCurrencies();
  }, [auth]);

  return (
    <Provider value={{ currencies, setCurrencies, baseCurrency }}>
      {children}
    </Provider>
  );
};
